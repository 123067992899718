// JavaScript source code
import './../styles/AccountComponents/SubscriptionInfo.css';
import { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import Popup from 'reactjs-popup';
import LudareCheckoutForm from "./../CheckoutForm";
import LoadingPopup from './../LoadingPopup';
import { IsValid, GetPaymentMethods, GetSubscription } from "./../Utilities";
import { useSearchParams, useNavigate, Navigate, useParams } from "react-router-dom";

function SubscriptionInfo({ accountInfo, setAccountInfo }) {
    const [selectedPayment, setSelectedPayment] = useState(undefined);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showPayments, setShowPayments] = useState(false);
    const [subscription, setSubscription] = useState(undefined);
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [newSubscriptionAmount, setNewSubscriptionAmount] = useState(0);
    const [subscriptionPopupClosed, setSubscriptionPopupClosed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadTillValid, setLoadTillValid] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [subUpdating, setSubUpdating] = useState(false);
    const [editSubscription, setEditSubscription] = useState(false);
    const { page, id } = useParams();
    const navigate = useNavigate();
    var tempSubscription;

    useEffect(() => {
        setLoading(true);
        if (accountInfo !== undefined) {
            GetSubscription(accountInfo, setSubscription);
            GetPaymentMethods(accountInfo, setPaymentMethods);
        }
    }, [accountInfo]);

    useEffect(() => {
        console.log("Sub Updated");

        if (IsValid(subscription) === true && IsValid(paymentMethods) === true) {
            setLoading(false);
            GetSubscriptionPrice();
            for (var i = 0; i < paymentMethods.length && subscription !== null && subscription !== undefined; i++) {
                if (paymentMethods[i].id === subscription.default_payment_method) {
                    console.log(paymentMethods[i]);
                    setSelectedPayment(paymentMethods[i]);
                }
            }
        }
        else {
            if (loadTillValid === true) {
                GetSubscription(accountInfo, setSubscription);
            }
            console.log("No Subscription Set");
            setLoading(false);
        }
    }, [subscription, paymentMethods]);

    

    

    function LoadSubscriptionTilValid() {
        setLoading(true);
        setLoadTillValid(true);
        GetSubscription(accountInfo, setSubscription);
    }

    function GetSubscriptionPrice() {
        if (subscription === null || subscription === undefined) return;
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'price': subscription.items.data[0].price.id })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GetStripePrice", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSubscriptionAmount(data.price.unit_amount);
                    setNewSubscriptionAmount(data.price.unit_amount);
                }
            });
    }

    function PaymentMenuOnClick() {
        setShowPayments(!showPayments);
    }

    function PaymentSelected(paymentMethod) {
        setSelectedPayment(paymentMethod);
        setShowPayments(false);
    }

    function NewPaymentSelected() {
        setPopupOpen(true);
        setSelectedPayment(undefined);
        setShowPayments(false);
    }

    function DeletePaymentMethod() {
        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'payment': selectedPayment.id })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/RemovePayment", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                if (data.success) {
                    GetPaymentMethods(accountInfo, setPaymentMethods);
                }
            });
    }

    function StartSubscription(price, paymentMethod) {
        setSubUpdating(true);

        console.log(accountInfo);

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId, 'price': price, "paymentSource": paymentMethod.id, "trial": accountInfo.TrialUsed !== 1 }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/CreateSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                if (data.success) {
                    var callback = function () {
                        GetPaymentMethods(accountInfo, setPaymentMethods);
                        LoadSubscriptionTilValid();
                    }

                    ReactGA.gtag('event', 'conversion_event_subscribe_paid', {
                        'event_callback': callback,
                        'event_timeout': 2000,
                    });
                }
            });
    }

    function CheckAndMakeSubscription() {
        if (selectedPayment === undefined || newSubscriptionAmount < 4.99) {
            return;
        }
        else {
            //console.log(selectedPaymentAmount);
            StartSubscription(newSubscriptionAmount, selectedPayment);
        }
        setSubscriptionPopupClosed(true);
    }

    function IsSubActive() {
        return (IsValid(subscription) === true && (subscription.status === "active" || subscription.status === "trialing"));
    }

    function IsSubTrial() {
        return (IsValid(subscription) === true && subscription.status === "trialing");
    }

    function GetNextBillDate() {
        const billDate = new Date(subscription.current_period_end * 1000);
        return billDate.toLocaleDateString();
    }

    function CancelSubscription() {
        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'subscription': subscription.id }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/CancelSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                if (data.success) {
                    setSubscription(undefined);
                }
            });
    }

    function ClosePaymentPopup() {
        setPopupOpen(false);
    }

    function UpdateSubscription() {
        navigate("/account/updateSubscription");
    }

    function UpdatePayment() {
        navigate("/account/updatePaymentMethod");
    }

    return (
        <div className="subscriptionParentDiv">
            {loading === true &&
                <LoadingPopup />
            }
            {loading === false && page === "subscription" &&
                <div className="subscriptionMenu">
                    {subUpdating === true &&
                        <div className="subUpdatingPopup">
                            <img className="loadingIcon" src="loading-placeholder.gif" />
                        </div>
                    }
                    <div className="subscriptionStatus">
                        <h3 className="subStatusTitle">Subscription Status</h3>
                        <div>
                            <p className="subscriptionStatusYellowText">Status:</p>
                            <p className="subStatusResult" style={{ color: IsSubActive() ? "lightgreen" : "red" }}>{IsSubActive() ? (IsSubTrial() ? "Trial" : "Active") : "Inactive"}</p>
                        </div>
                        {IsSubActive() === true && <div>
                            <p className="subscriptionStatusYellowText">{subscription.cancel_at_period_end ? "Canceling On:" : "Next Billing Date:"}</p>
                            <p className="subscriptionStatusYellowText">{GetNextBillDate()}</p>
                        </div>}
                    </div>
                    <hr />
                    {IsSubActive() === true && <div>
                        <div className="subscriptionPaymentMenu">
                            <h3 className="subscriptionTitle">Current Subscription</h3>
                            <p className="currSubscriptionAmount">${parseFloat(newSubscriptionAmount / 100).toFixed(2) + "/ Month"}</p>
                            <button className="accountPageButton" onClick={UpdateSubscription }>Modify Subscription</button>
                        </div>
                        <hr />
                        <div className="creditInfoForm">
                            <h3 className="paymentMethodTitle">Payment Method</h3>
                            {IsValid(subscription) === true && IsValid(selectedPayment) === true && <PaymentMethodListItem paymentMethod={selectedPayment} clickable={false} />}
                            <button className="accountPageButton" onClick={UpdatePayment}>Update Payment</button>
                        </div>
                        <hr />
                        {IsSubActive() === true && <div>
                            <button className="deactivateSubscription accountPageButton" onClick={CancelSubscription} >Cancel Subscription</button>
                        </div>}
                        {IsSubActive() === false && newSubscriptionAmount !== 0 && selectedPayment !== undefined && <div>
                            <button className="deactivateSubscription accountPageButton" onClick={CheckAndMakeSubscription} >Start Subscription</button>
                        </div>}
                    </div>}
                    {IsSubActive() === false && <SubscriptionOptions subActive={IsSubActive()} />}
                </div>}
            {loading === false && page === "updateSubscription" && <SubscriptionOptions subActive={IsSubActive()} subscriptionUpdate={true} currentSubscription={ subscriptionAmount } />}
        </div>
    );
}

export function PaymentMethodListItem({ paymentMethod, selected, clickable, breakAfter, subscription }) {

    function SelectedMethod() {
        if (clickable === true) selected(paymentMethod);
    }

    return (
        <div className="selectPaymentMenuDropdownItem" onClick={SelectedMethod}>
            <div className="leftCardInfo">
                <p className="paymentTypeBrand">{paymentMethod.card.brand}</p>
                <p className="expiration">Exp: {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</p>
            </div>
            <div className="rightCardInfo">
                {subscription !== null && subscription !== undefined && paymentMethod.id === subscription.default_payment_method && <p className="defaultPaymentMarker">DEFAULT</p>}
                <p className="lastDigits">Last 4:{paymentMethod.card.last4}</p>
            </div>
        </div>
    );
}

function SetSubscriptionPopup({ accountInfo, open, setSubscription }) {
    const [tempSubscription, setTempSubscription] = useState();

    return (
        <Popup open={open} modal nested lockScroll >
            {close => (
                <div className="subscriptionPaymentPopup">
                    <h3 className="subscriptionTitle">Monthly Subscription</h3>
                    <label className="subscriptionPopupSubTitle" htmlFor="subAmount">Current Subscription Amount</label> <br />
                    <span className="subSpan">
                        <p className="currencySymbol">$</p>
                        <input type="number" step=".01" min="2.00" className="currSubscriptionAmountSetup" onChange={(e) => (setTempSubscription(e.target.value * 100))} defaultValue={4.99} />
                    </span>
                    <div className="subscriptionEditButtons">
                        <button className="confirmEdit" enabled={(tempSubscription > 4.99 && tempSubscription > 499) ? "true" : "false"} onClick={() => { setSubscription(tempSubscription); close(); }}>Set Subscription</button>
                        <button className="cancelEdit" onClick={(event) => { event.preventDefault(); close(); }}>Cancel</button>
                    </div>
                </div>
            )}
        </Popup>);
}

function SubscriptionOptions({ subActive, subscriptionUpdate, currentSubscription }) {
    const navigate = useNavigate();
    const [subscriptionIndex, setSubscriptionIndex] = useState(-1);
    const [subscriptionAmmount, setSubscriptionAmmount] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        if (IsValid(currentSubscription) === false) return;
        switch (currentSubscription) {
            case 499:
                setSubscriptionIndex(0);
                break;
            case 699:
                setSubscriptionIndex(1);
                break;
            case 999:
                setSubscriptionIndex(2);
                break;
            default:
                setSubscriptionIndex(3);
                break;
        }
        setSubscriptionAmmount(currentSubscription);
    }, []);

    function ClickLow() {
        setSubscriptionAmmount(499);
        setSubscriptionIndex(0);
    }

    function ClickMiddle() {
        setSubscriptionAmmount(699);
        setSubscriptionIndex(1);
    }

    function ClickHigh() {
        setSubscriptionAmmount(999);
        setSubscriptionIndex(2);
    }

    function ClickCustom() {
        setPopupOpen(true);
    }

    function NewSubscriptionSelected() {
        localStorage.setItem("subscriptionLevel", subscriptionAmmount);
        navigate("/account/selectPaymentMethod");
    }

    function UpdateSubscription() {
        localStorage.setItem("subscriptionLevel", subscriptionAmmount);
        navigate("/account/confirmUpdateSubscription");
    }

    function CustomSubscriptionSet(subscriptionLevel) {
        setSubscriptionAmmount(subscriptionLevel);
        setSubscriptionIndex(3);
        setPopupOpen(false);
    }

    return (<div className="subscriptionOptionsContainer">
        <p className="subscriptionOptionTitles">{subActive ? "Modify Subscription" : "Start Subscription"}</p>
        <div className={"subscriptionOption " + (subscriptionIndex === 0 ? "subscriptionOptionSelected" : "")} onClick={ClickLow}>
            <p className="planTitle">Indie Access</p>
            <p className="planDescription">Get access to the full library of indie games and support developers at the minimum level.</p>
            <p className="planPrice">$4.99/ Month</p>
        </div>
        <div className={"subscriptionOption " + (subscriptionIndex === 1 ? "subscriptionOptionSelected" : "")} onClick={ClickMiddle}>
            <p className="planTitle">Indie Supporter</p>
            <p className="planDescription">Get access to the full library of indie games and support developers at a higher level.</p>
            <p className="planPrice">$6.99/ Month</p>
        </div>
        <div className={"subscriptionOption " + (subscriptionIndex === 2 ? "subscriptionOptionSelected" : "")} onClick={ClickHigh}>
            <p className="planTitle">Indie Champion</p>
            <p className="planDescription">Get access to the full library of indie games and support developers at the highest level.</p>
            <p className="planPrice">$9.99/ Month</p>
        </div>
        <div className={"subscriptionOption " + (subscriptionIndex === 3 ? "subscriptionOptionSelected" : "")} onClick={ClickCustom}>
            <p className="planTitle">Custom Support</p>
            <p className="planDescription">Get access to the full library of indie games and support developers at a custom level. <br/> Must be above the minimum price of $4.99/ month.</p>
            <p className="planPrice">{"$" + (subscriptionIndex === 3 ? subscriptionAmmount/100 : "?") + "/ Month"}</p>
        </div>
        <SetSubscriptionPopup open={popupOpen} setSubscription={CustomSubscriptionSet} />
        <button className={"accountPageButton selectsubscriptionButton " + (subscriptionIndex === -1 ? "disabledButton" : "")} onClick={subscriptionUpdate ? UpdateSubscription : NewSubscriptionSelected} disabled={subscriptionIndex === -1}>Select Subscription</button>
    </div>);
}

export default SubscriptionInfo;