// JavaScript source code
import "./styles/AccountPage.css";
import { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate, Navigate, useParams } from "react-router-dom";
import { GetAccessToken, IsValid, FetchAccountInfo} from "./Utilities";
import ReactGA from 'react-ga4';
import PersonalAccountMenu from './AccountComponents/PersonalAccountMenu.js';
import NewDeveloperPage from './AccountComponents/NewDeveloperPage.js';
import DeveloperPage from './AccountComponents/DeveloperPage.js';

ReactGA.initialize('G-P1CP4P4GZ6');

function AccountPage({ status }) {
    const [accountInfo, setAccountInfo] = useState();
    const [queryParams] = useSearchParams();
    const [pageStatus, setPageStatus] = useState("");
    const [currDevId, setCurrDevId] = useState();
    const [gameArray, setGameArray] = useState([]);
    const [devArrayCount, setDevArrayCount] = useState(0);
    const [developerArray, setDeveloperArray] = useState([]);
    const [currGame, setCurrGame] = useState();
    const tempDevArray = useRef([]);
    const targetDevID = useRef();
    const { page, id } = useParams();
    const navigate = useNavigate();
    const [developerDropdown, setDeveloperDropdown] = useState(false);
    //const tempGameArray = useRef([]);

    function AccountClick() {
        navigate("/account");
    }

    useEffect(() => {
        

        FetchAccountInfo(setAccountInfo);

        if (queryParams.get("steamID") !== undefined && queryParams.get("steamID") !== null) {
            GetAccessToken().then((token) => {
                const idReqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'token': token, 'ID': queryParams.get("steamID"), 'type': "steam" })
                };

                fetch(process.env.REACT_APP_NODE_ADDRESS + "/RegisterID", idReqOptions)
                    .then((res) => res.json())
                    .then((data) => { FetchAccountInfo(setAccountInfo); });
            });
        }

        if (queryParams.get("epicID") !== undefined && queryParams.get("epicID") !== null) {
            GetAccessToken().then((token) => {
                const idReqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'token': token, 'ID': queryParams.get("epicID"), 'type': "epic" })
                };

                fetch(process.env.REACT_APP_NODE_ADDRESS + "/RegisterID", idReqOptions)
                    .then((res) => res.json())
                    .then((data) => { FetchAccountInfo(setAccountInfo); });
            });
        }

        if (IsValid(page) !== false) {
            setPageStatus(page);
        }

        if (page === "developer") {
            targetDevID.current = id;
        }

        if (page === "game") {
           setCurrGame(id);
        }

    }, [queryParams, id, page]);

    useEffect(() => {
        if (accountInfo === undefined || accountInfo === null) {
            return;
        }

        if (accountInfo.DeveloperOn !== null && accountInfo.DeveloperOn.length > 0) {

            var devIds = JSON.parse(accountInfo.DeveloperOn);

            if (devIds !== undefined && devIds !== null) {
                devIds.map((developerId, index) => {
                    var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetDeveloperInfo";

                    const reqOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 'developerId': developerId })
                    };

                    fetch(URL, reqOptions)
                        .then((res) => res.json())
                        .then((data) => {

                            var newDevArray = tempDevArray.current;

                            var lastIndex = -1;

                            if (data.message === undefined) {
                                return;
                            }

                            if (targetDevID.current === data.message.DeveloperID) {
                                setCurrDevId(targetDevID.current);
                            }

                            for (var i = 0; i < newDevArray.length; i++) {
                                if (newDevArray[i].DeveloperID === data.message.DeveloperID) {
                                    lastIndex = i;
                                }
                            }

                            if (lastIndex === -1) {
                                newDevArray = [...newDevArray, data.message];
                            }

                            tempDevArray.current = newDevArray;

                            setDeveloperArray(tempDevArray.current);
                            setDevArrayCount(newDevArray.length);
                        });
                });
            }
        }
    }, [accountInfo])

    useEffect(() => {
        setCurrDevId(targetDevID.current);
    }, [targetDevID, developerArray]);

    useEffect(() => {

        var devInfo;
        for (var i = 0; i < developerArray.length; i++) {
            if (currDevId !== undefined && currDevId === developerArray[i].DeveloperID) {
                devInfo = developerArray[i];
                break;
            }
        }

        var newGamesArray = [];

        if (devInfo === undefined || devInfo === null) return;

        var games = JSON.parse(devInfo.Game);

        if (games !== undefined && games !== null) {
            games.map((gameID, Index) => {

                if (newGamesArray.indexOf(gameID) === -1) {
                    newGamesArray = [...newGamesArray, gameID];

                }
                return true;
            });
        }

        setGameArray(newGamesArray);

    }, [currDevId, developerArray])

    function ToggleDeveloperDropdown() {
        setDeveloperDropdown(!developerDropdown);
    }

    function DeveloperListArray() {

        return (
            <div className="devArrayDiv">
                {devArrayCount > 0 && gameArray.map !== null && (developerArray.map((DeveloperInfo, index) => (
                    <DeveloperListItem developerInfo={DeveloperInfo} setPage={setPageStatus} setDevId={setCurrDevId} setCurrGame={setCurrGame} currDevId={currDevId} />
                )))}
                <DeveloperListItem />
            </div>
        );
    }

    return (
        <div className="accountPage">
            {!status && <Navigate to='/' />}
            <ul className="sideMenu">
                <li><p className={"personalHeader " + ((IsValid(page) === false || page === "subscription" || page === "linkAccounts" || page === "selectPaymentMethod" || page === "paymentMethod" || page === "confirmSubscription" || page === "updateSubscription") ? "selectedMenuItem" : "unselectedMenuItem")} onClick={AccountClick}>Account</p></li>
                <li>
                    <span className="developerHeaderSpan" onClick={ToggleDeveloperDropdown}><p className="developerHeader" >Developer</p> <p className="dropdownIndicator">{developerDropdown ? "∨" : "<"}</p></span>
                    {developerDropdown === true && <DeveloperListArray />}
                </li>
            </ul>
            <div className="verticalBreak" />
            <div className="infoPane">
                {(IsValid(page) === false || page === "subscription" || page === "linkAccounts" || page === "selectPaymentMethod" || page === "paymentMethod" || page === "confirmSubscription" || page === "updateSubscription" || page === "confirmUpdateSubscription" || page === "updatePaymentMethod") && <PersonalAccountMenu accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
                {page === "developer" && <DeveloperPage devId={currDevId} accountInfo={accountInfo} gameArray={gameArray} devArray={developerArray} currGame={currGame} setCurrGame={setCurrGame} /> }
                {page === "newDeveloper" && <NewDeveloperPage accountInfo={accountInfo} />}
            </div>
        </div>
    );
}

export default AccountPage;

function DeveloperListItem({ developerInfo, setDeveloperId, setPage, setDevId, setCurrGame, currDevId, setCurrDevId, currPage }) {
    const [developerName, setDeveloperName] = useState("");
    const navigate = useNavigate();
    const { page, id } = useParams();

    useEffect(() => {

        if (developerInfo === null || developerInfo === undefined) {
            setDeveloperName("+ Create New Developer");
        }
        else {
            setDeveloperName(developerInfo.DevName);
        }
    }, [developerInfo])

    function SetDeveloperPage() {

        if (developerInfo === null || developerInfo === undefined) {
            navigate("/account/newDeveloper/");
        }
        else {
            setDevId(developerInfo.DeveloperID);

            navigate("/account/developer/" + developerInfo.DeveloperID);
        }
    }

    return (
        <div>
            {developerName !== undefined && <button className={"developerMenuButton " + (((developerInfo !== undefined && IsValid(id) === true && parseInt(id) === developerInfo.DeveloperID) || (developerInfo === undefined && page === "newDeveloper")) ? "developerMenuButtonSelected" : "developerMenuButtonUnselected") } onClick={SetDeveloperPage}>{developerName}</button>}
        </div>
    );
}

