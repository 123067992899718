// JavaScript source code
import '../styles/AccountComponents/BasicAccountInfo.css';
import { useEffect, useState, useRef } from 'react';
import { CheckUsername, FetchAccountInfo } from "./../Utilities";
import Popup from 'reactjs-popup';

function BasicAccountInfo({ accountInfo, setAccountInfo }) {
    //console.log(accountInfo);
    const [displayName, setDisplayName] = useState();
    const [usernameCheck, setUsernameCheck] = useState(undefined);
    const [usernameCheckRes, setUsernameCheckRes] = useState("");
    var tempDisplay = "";

    useEffect(() => {
        if (accountInfo !== null && accountInfo !== undefined) {
            setDisplayName((accountInfo.DisplayName !== null && accountInfo.DisplayName !== undefined) ? accountInfo.DisplayName : accountInfo.Username);
        }
    }, [accountInfo]);

    function UpdateDisplayName() {
        if (usernameCheck === false) return;

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': accountInfo.username, 'newName': tempDisplay })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateDisplayName', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                FetchAccountInfo(setAccountInfo);
            });
    }

    function CheckCurrentUsername(event) {
        event.preventDefault();

        CheckUsername(tempDisplay, setUsernameCheck, setUsernameCheckRes);
    }

    const ModifyUsernamePopup = ({ accountInfo }) => (
        <Popup trigger={<button className="modifyUsername">Update</button>} modal nested lockScroll >
            {close => (
                <form className="displayNameEditForm">
                    <label className="editNameLabel" htmlFor="displayName">Display Name:</label>
                    <input type="text" id="displayName" name="displayName" onChange={(e) => { e.preventDefault(); tempDisplay = e.target.value; }} />
                    <div className="confirmUsername"><button className="checkName" onClick={CheckCurrentUsername}>Check DisplayName</button> {usernameCheck !== undefined && <p className="usernameCheckRes" style={{ color: (usernameCheck === true) ? "lightgreen" : "red" }}>{usernameCheckRes}</p>} </div>
                    <div className="displayNameEditButtons"> <button className="confirmEdit" onClick={UpdateDisplayName}>Update Display Name</button> <button className="cancelEdit" onClick={(event) => { event.preventDefault(); close(); }}>Cancel</button> </div>
                </form>
            )}
        </Popup>
    );

    return (
        <div>
            {accountInfo !== undefined && (<div className="accountInfoPane" >
                <div className="usernameBlock">
                    <p className="accountUsername">Username:</p>
                    <div className="modifyUsernameBlock">
                        <p> {displayName} </p>

                    </div>
                </div>
                <hr />
                <div className="emailBlock">
                    <p className="accountEmail">Email:</p>
                    <p className="emailField">{accountInfo.Email}</p>
                </div>
            </div>)}
        </div>

    );
}

export default BasicAccountInfo;