// JavaScript source code
import './../styles/AccountComponents/SelectPaymentMethod.css';
import { PaymentMethodListItem } from './SubscriptionInfo';
import { useSearchParams, useNavigate, Navigate, useParams } from "react-router-dom";
import { GetAccessToken, IsValid, FetchAccountInfo, GetPaymentMethods, GetSubscription } from "./../Utilities";
import LudareCheckoutForm from "./../CheckoutForm";
import { useEffect, useState, useRef } from 'react';

function SelectPaymentMethod() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(undefined);
    const [accountInfo, setAccountInfo] = useState(undefined);
    const [subscription, setSubscription] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        FetchAccountInfo(setAccountInfo);
    }, []);

    useEffect(() => {
        console.log(accountInfo);
        if (IsValid(accountInfo) === false) return;
        GetPaymentMethods(accountInfo, setPaymentMethods);
        GetSubscription(accountInfo, setSubscription);
    }, [accountInfo]);

    useEffect(() => {
        console.log(subscription);
        if (IsValid(subscription) === false) return;
        setSelectedMethod(subscription.default_payment_method);
        console.log(subscription.default_payment_method);
    }, [subscription])

    function ConfirmPayment() {
        localStorage.setItem('paymentMethod', selectedMethod);
        navigate("/account/confirmSubscription");
    }

    return (
        <div className="paymentMethodList">
            {IsValid(paymentMethods) === true && paymentMethods.map((method) => {
                return <SelectPaymentMethodListItem currentSelect={selectedMethod} paymentMethod={method} clickable={true} breakAfter={true} selected={setSelectedMethod} subscription={subscription} />;
            })}
            <NewPaymentMethodListItem accountInfo={accountInfo} currentSelect={selectedMethod} selected={setSelectedMethod} />
            {IsValid(selectedMethod) === true && selectedMethod !== -1 && <button className="accountPageButton confirmSubscriptionPayment" onClick={ConfirmPayment}>Confirm Payment</button> }
        </div>
    );
}

export function SelectPaymentMethodListItem({ paymentMethod, selected, clickable, breakAfter, subscription, currentSelect }) {

    function SelectedMethod() {
        if (clickable === true) selected(paymentMethod.id);
    }

    return (
        <div className="selectPaymentMenuDropdownItem" onClick={SelectedMethod}>
            <input type="radio" className="coloredRadio" checked={IsValid(selected) === true && paymentMethod.id === currentSelect }></input>
            <div className="leftCardInfo">
                <p className="paymentTypeBrand">{paymentMethod.card.brand}</p>
                <p className="expiration">Exp: {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</p>
            </div>
            <div className="rightCardInfo">
                {subscription !== null && subscription !== undefined && paymentMethod.id === subscription.default_payment_method && <p className="defaultPaymentMarker">DEFAULT</p>}
                <p className="lastDigits">Last 4:{paymentMethod.card.last4}</p>
            </div>
        </div>
    );
}

export function NewPaymentMethodListItem({ accountInfo, currentSelect, selected }) {

    function NewMethodSelected() {
        selected(-1);
        console.log("New payment");
    }

    return (
        <div className="newPaymentMethodContainer">
            <div className="selectNewPaymentMenuDropdownItem" onClick={NewMethodSelected }>
                <input type="radio" className="coloredRadio selectPaymentRadio" checked={IsValid(currentSelect) === true && currentSelect < 0}></input>
                <p className="newPaymentMethodTitle">+ Add New Payment Method</p>
            </div>
            {IsValid(currentSelect) === true && currentSelect < 0 && <LudareCheckoutForm accountInfo={accountInfo} />}
        </div>
    );
}

export default SelectPaymentMethod;