// JavaScript source code
import './../styles/AccountComponents/NewDeveloperPage.css';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, Navigate } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

function NewDeveloperPage({ accountInfo }) {
    const [reloadPage, setReloadPage] = useState(false);
    const devId = useRef("");
    const [stateCountry, setStateCountry] = useState();
    const [stateRegion, setStateRegion] = useState();
    const [message, setMessage] = useState();

    const studioName = useRef();
    const contactEmail = useRef();
    const streetAddress1 = useRef();
    const streetAddress2 = useRef();
    const city = useRef();
    const region = useRef();
    const country = useRef();
    const zipCode = useRef();
    const EIN = useRef();

    function SubmitNewDeveloperRequest(event) {
        event.preventDefault();

        var address = {
            'streetAddress1': streetAddress1.current,
            'streetAddress2': streetAddress2.current,
            'city': city.current,
            'region': stateRegion,
            'country': stateCountry,
            'zipCode': zipCode.current
        };

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/MakeNewDeveloper";

        if (studioName.current === undefined || contactEmail.current === undefined || streetAddress1.current === undefined || city.current === undefined || stateCountry === undefined || stateRegion === undefined || zipCode.current === undefined) {
            setMessage("Missing requried information to register new developer.")
            return;
        }

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'studioName': studioName.current, 'email': contactEmail.current, 'userId': accountInfo.LudareID, 'ein': EIN.current, 'address': JSON.stringify(address) })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {

                if (data.success === true) {
                    devId.current = data.message;
                    //setReloadPage(true);
                    window.location.assign("/Account?loadPage=Developer&loadDev=" + devId.current);
                }
                else {
                    setMessage(data.message);
                }

            });

    }

    function UpdateCountry(val) {
        country.current = val;
        setStateCountry(country.current);
    }

    function UpdateState(val) {
        region.current = val;
        setStateRegion(region.current);
    }

    return (
        <div>
            {message !== undefined && <p className="newDevMessage">{message}</p>}
            <h1 className="newDevtitle">Create New Developer</h1>
            <span className="requiredHeader"> <p className="requiredLabel">Required = </p> <p className="requiredMark">{'\u2731'}</p> </span>
            <form className="newDeveloperForm">
                <div>
                    <label htmlFor="studioName">Studio Name:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="studioName" required onChange={(e) => studioName.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="studioEmail">Studio Contact Email:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="email" id="studioEmail" required onChange={(e) => contactEmail.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="streetAddress1">Street Address 1:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="streetAddress1" required onChange={(e) => streetAddress1.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="streetAddress2">Street Address 2:</label>
                    <input type="text" id="streetAddress2" onChange={(e) => streetAddress2.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="city">City:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="city" required onChange={(e) => city.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="country">Country:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <CountryDropdown onChange={UpdateCountry} required value={stateCountry} style={{
                        float: 'right',
                        width: '50%',
                        height: 'fit-content',
                        margin: 'auto', 'margin-left': '0px'}} />
                </div>
                <div>
                    <label htmlFor="state">State:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <RegionDropdown country={stateCountry} required value={stateRegion} onChange={UpdateState} style={{
                        float: 'right',
                        width: '50%',
                        height: 'fit-content',
                        margin: 'auto', 'margin-left': '0px'
                    }} />
                </div>
                <div>
                    <label htmlFor="zipCode">Zip Code:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="zipcode" required onChange={(e) => zipCode.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="ein">Tax Identification Number:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="ein" required onChange={(e) => EIN.current = e.target.value} />
                </div>
                <button className="makeNewDeveloper accountPageButton" onClick={SubmitNewDeveloperRequest}>Create</button>
                {reloadPage === true && <Navigate to={"/Account?loadPage=Developer&loadDev=" + devId.current}/>}
            </form>
        </div>
    );
}

export default NewDeveloperPage;