// JavaScript source code
import './../styles/AccountComponents/LinkedAccountInfo.css';

function LinkedAccountInfo({ accountInfo, setAccountInfo }) {

    return (
        <div className="accountLinks">
            {accountInfo !== undefined && <div className="steamLink">
                <a href={process.env.REACT_APP_NODE_ADDRESS + "/auth/steam?userID=" + accountInfo.LudareID}>
                    <img className="platformImg" src="/SteamLoginImage.png" alt="Steam required login icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Steam </p> <br />
                <p style={{ color: (accountInfo.SteamID !== null && accountInfo.SteamID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.SteamID && accountInfo.SteamID !== undefined) !== null ? "Linked" : "Not Linked"} </p>
            </div>}
            {accountInfo !== undefined && <div className="epicLink">
                <a href={"https://www.epicgames.com/id/authorize?client_id=xyza7891yUZ9T5c0Ei459DIUyKn7vglR&response_type=code&scope=basic_profile&redirect_uri=https://www.devpowered.com:8000/auth/epic"}>
                    <img className="platformImg" src="/epic_games_black_logo.png" alt="Epic icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Epic </p> <br />
                <p style={{ color: (accountInfo.EpicID !== null && accountInfo.EpicID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.EpicID !== null && accountInfo.EpicID !== undefined) ? "Linked" : "Not Linked"} </p>
            </div>}
            {accountInfo !== undefined && <div className="googleLink">
                <a href={"https://accounts.google.com/o/oauth2/v2/auth?client_id=289770615480-dk7l2cnsr9jqecgtidmtbfor5gcalagh.apps.googleusercontent.com&response_type=code&scope=openid%20email&redirect_uri=" + process.env.REACT_APP_BASE_ADDRESS + "/GoogleReturn"}>
                    <img src="/Android.png" alt="Google icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Android </p> <br />
                <p style={{ color: (accountInfo.GoogleID !== null && accountInfo.GoogleID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.GoogleID !== null && accountInfo.GoogleID !== undefined) ? "Linked" : "Not Linked"} </p>
            </div>}
        </div>

    );
}

export default LinkedAccountInfo;