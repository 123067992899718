// JavaScript source code
import './../styles/AccountComponents/GamePage.css';
import { useEffect, useState, useRef } from 'react';
import AWS from "aws-sdk";
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import { Upload } from '@aws-sdk/lib-storage';
import { GetAccessToken, IsValid, FetchAccountInfo } from "./../Utilities";
import GameCard from './../components/library/Game.js';

function GamePage({ gameId }) {
    const [gameInfo, setGameInfo] = useState();
    const [gameData, setGameData] = useState();
    const [upload, setUpload] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [buildUploadProgress, setBuildUploadProgress] = useState(null);
    const backgroundImage = useRef(null);
    const keyImage = useRef(null);
    const buildFile = useRef(null);
    const [tags, setTags] = useState([]);
    const [gameTags, setGameTags] = useState([]);

    useEffect(() => {
        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: ""
        }

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetTagInfo', req)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    data.message.sort((a, b) => {
                        if (a.TagName < b.TagName) {
                            return -1;
                        }
                        if (a.TagName > b.TagName) {
                            return 1;
                        }
                        return 0;
                    });
                    setTags(data.message);
                }
            });
    }, [])

    useEffect(() => {
        GetGameInfo();

        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/GetGameEvents";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setGameData(data.message);
                }
            });

    }, [gameId]);

    useEffect(() => {
        if (refresh === true) setRefresh(false);
    }, [refresh])

    useEffect(() => {
        if (IsValid(gameInfo)) {
            var tmpGameInfo = gameInfo;
            tmpGameInfo.TagId = gameTags;
            setGameInfo(tmpGameInfo);
        }
    }, [gameTags])

    function GetGameInfo() {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetGameInfo";

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    if (IsValid(data.message.PublicBuildLinks) === true) {
                        data.message.PublicBuildLinks = JSON.parse(data.message.PublicBuildLinks);
                    }

                    if (IsValid(data.message.PrivateBuildLinks) === true) {
                        data.message.PrivateBuildLinks = JSON.parse(data.message.PrivateBuildLinks);
                    }
                    setGameInfo(data.message);
                    setGameTags(data.message.TagId);

                    //console.log(data.message);
                }
            });
    }

    function UploadImage(gameId, type, fileContents) {
        setUpload(false);
        const name = `games/${gameId}/${type}.png`

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_S3_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        })

        const params = {
            Bucket: S3_BUCKET,
            Key: name,
            Body: fileContents,
        }

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                console.log("Updating " + parseInt((evt.loaded * 100) / evt.total) + "%");
            }).promise().then((err, data) => {
                console.log("File Uploaded");
                setUpload(true);
                var tmpGameInfo = gameInfo;
                tmpGameInfo.BackgroundUrl = gameInfo.BackgroundUrl;
                tmpGameInfo.PhotoUrl = gameInfo.PhotoUrl;
                setGameInfo(tmpGameInfo);
                setRefresh(true);
            });

    }

    function BuildUpload() {
        setUpload(false);
        var fileName = document.getElementById('uploadBuild').files[0].name;

        const name = `games/${gameInfo.ID}/${fileName}`;

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_S3_REGION;

        const params = {
            Bucket: S3_BUCKET,
            Key: name,
            Body: document.getElementById('uploadBuild').files[0],
        }

        const s3 = new S3Client({
            region: REGION,
            credentials: {
                accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
            },
            requestHandler: new XhrHttpHandler({})
        });

        const upload = new Upload({
            client: s3,
            params: params
        });

        upload.on("httpUploadProgress", (progressEvent) => {
            setBuildUploadProgress({ 'loaded': progressEvent.loaded, 'total': progressEvent.total });
            console.log("Updating " + parseInt((progressEvent.loaded * 100) / progressEvent.total) + "%");
        })

        upload.done().then((err, data) => {
            console.log("File Uploaded");
            setUpload(true);

            UpdateBuildLinks(name);
        });
    }

    function UpdateBuildLinks(newLink) {
        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/GameBuildUploaded";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId, 'buildLink': newLink })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    var tempGameInfo = gameInfo;
                    tempGameInfo.PrivateBuildLinks = JSON.parse(data.message);
                    setGameInfo(tempGameInfo);
                    console.log(tempGameInfo);
                    setRefresh(true);
                }
            });
    }

    function CopySecret(event) {
        event.preventDefault();

        navigator.clipboard.writeText(gameInfo.Secret);

        alert("Secret Copied");
    }

    function UploadKeyArtPictureLink(gameId) {
        const keyArt = `games/${gameId}/keyArt.png`
        const background = `games/${gameId}/background.png`
        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gameId: gameId,
                secretKey: "J*oKUE8TytLJRaE298%-R3VHHnk#ybmtN-x9aA#HBV$hKwC1Q%PzEeHLp%a-(%9EhHJiFfBza*yQ4U-LZLCy8y_prT*YzEIIa12)",
                photoUrl: keyArt
            })
        }

        try {
            fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateGame', req)
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        setRefresh(true);
                        //return data;
                    } else {
                        throw new Error(data.message || 'Failed to update game info');
                    }
                });


        } catch (error) {
            console.error('Error updating game info:', error);
            return null;
        }
    }

    function UploadBackgroundPictureLink(gameId) {
        const keyArt = `games/${gameId}/keyArt.png`
        const background = `games/${gameId}/background.png`
        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gameId: gameId,
                secretKey: "J*oKUE8TytLJRaE298%-R3VHHnk#ybmtN-x9aA#HBV$hKwC1Q%PzEeHLp%a-(%9EhHJiFfBza*yQ4U-LZLCy8y_prT*YzEIIa12)",
                backgroundUrl: background
            })
        }

        try {
            fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateGame', req)
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        return data;
                    } else {
                        throw new Error(data.message || 'Failed to update game info');
                    }
                });


        } catch (error) {
            console.error('Error updating game info:', error);
            return null;
        }
    }

    function UploadKeyArt() {
        const gameId = gameInfo.ID;
        const keyArt = document.getElementById('uploadKeyArt').files[0];

        UploadImage(gameId, "keyArt", keyArt);
        UploadKeyArtPictureLink(gameId);
    }

    function UploadBackgroundArt() {
        const gameId = gameInfo.ID;
        const background = document.getElementById('uploadBackground').files[0];

        UploadImage(gameId, "background", background);
        UploadBackgroundPictureLink(gameId);
    }

    function ToggleVibility() {
        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/SetGameVisibility";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId, 'visibility': !gameInfo.Visibility })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    var tempGameInfo = gameInfo;
                    tempGameInfo.Visibility = !gameInfo.Visibility;
                    setGameInfo(tempGameInfo);
                    setRefresh(true);
                }
            });
    }

    function UpdateGameInfo() {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/UpdateGame";

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameName': gameInfo.Name, 'gameId': gameInfo.ID, 'description': gameInfo.Description, 'tagId': gameTags, 'devNameOverride': gameInfo.DeveloperNameOverride, 'publisherName': gameInfo.PublisherName, 'websiteLink': gameInfo.WebsiteLink, 'steamLink': gameInfo.SteamLink })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {

            });
    }

    return (
        <div className="gameInfoPane">
            {IsValid(gameInfo) === true && refresh === false && gameInfo.Status === 4 &&
                <div className="gameInfoForm">
                    <div className="gamePropDiv">
                        <p className="gameVisiblityLabel">Current Visibility:</p><p className="gameVisiblityStatus" style={{ color: gameInfo.Visibility ? "lightgreen" : "red" }}>{gameInfo.Visibility ? "Visible" : "Not Visible"}</p><button className="visiblityToggleButton" onClick={ToggleVibility}>{gameInfo.Visibility ? "Set Not Visible" : "Set Visible"}</button>
                    </div> <br />

                    <div className="gamePropDiv">
                        <label htmlFor="gameId">Game ID:</label> <input className="gameInputField" id="gameId" readOnly value={gameInfo.ID} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameSecret">Game Secret:</label> <input className="gameInputField" type="password" id="gameSecret" readOnly value={gameInfo.Secret} />
                    </div>
                    <button className="copySecretButton accountPageButton" onClick={CopySecret} >Copy Secret</button>
                    <br />
                    <br />

                    <div className="gamePropDiv">
                        <label htmlFor="gameName">Game Name:</label><input className="gameInputField" id="gameName" onChange={(e) => gameInfo.Name = e.target.value} defaultValue={gameInfo.Name} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Game Description:</label> <textarea className="gameDescField" id="gameSecret" onChange={(e) => gameInfo.Description = e.target.value} defaultValue={gameInfo.Description} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Developer Name Override:</label> <input className="gameInputField" id="devOverride" onChange={(e) => gameInfo.DeveloperNameOverride = e.target.value} defaultValue={gameInfo.DeveloperNameOverride} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Publisher Name:</label> <input className="gameInputField" id="publisher" onChange={(e) => gameInfo.PublisherName = e.target.value} defaultValue={gameInfo.PublisherName} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Steam Link:</label> <input className="gameInputField" id="steamLink" onChange={(e) => gameInfo.SteamLink = e.target.value} defaultValue={gameInfo.SteamLink} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Website Link:</label> <input className="gameInputField" id="websiteLink" onChange={(e) => gameInfo.WebsiteLink = e.target.value} defaultValue={gameInfo.WebsiteLink} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameTags">Game Tags:</label>
                        <div className="gameTagsField">
                            {tags.map((tag, index) => {
                                return <GameTagEntry tagName={tag.TagName} tagId={tag.id} currGameTags={gameTags} setCurrGameTags={setGameTags} />
                            })}
                        </div>
                    </div> <br />
                    <button className="gameUpdateButton accountPageButton" onClick={UpdateGameInfo} >Update Game</button>
                    <br />
                    <p className="gameCardPreviewLabel">Library Listing Preview:</p>
                    <div className="gameCardPreview">
                        <GameCard gameId={gameId} gameInfo={gameInfo} />
                    </div>
                    <br />

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="gameUploadFieldDiv">
                            <label htmlFor="uploadBackground">Upload Background Here:</label>
                            <input type="file" id="uploadBackground" ref={backgroundImage} onChange={UploadBackgroundArt} style={{ display: 'none' }} />
                            <div className="backgroundUpload" onClick={(e) => { e.preventDefault(); backgroundImage.current.click(); }} >
                                <img className="uploadImageShow" src={process.env.REACT_APP_S3_BASE_URL + gameInfo.BackgroundUrl} alt="Current Background" />
                                <p className="uploadText">Click to select file to upload.</p>
                            </div>
                        </div>

                        <div className="gameUploadFieldDiv">
                            <label htmlFor="uploadKeyArt">Upload Key Art Here:</label>
                            <input type="file" id="uploadKeyArt" ref={keyImage} onChange={UploadKeyArt} style={{ display: 'none' }} />
                            <div className="backgroundUpload" onClick={(e) => { e.preventDefault(); keyImage.current.click(); }} >
                                <img className="uploadImageShow" src={process.env.REACT_APP_S3_BASE_URL + gameInfo.PhotoUrl} alt="Current Key Art" />
                                <p className="uploadText">Click to select file to upload.</p>
                            </div>
                        </div>

                        <div className="gameUploadFieldDiv">
                            <label htmlFor="uploadBuild">Upload Game Build Here:</label>
                            <input type="file" id="uploadBuild" ref={buildFile} onChange={BuildUpload} style={{ display: 'none' }} />
                            <div className="backgroundUpload" onClick={(e) => { e.preventDefault(); buildFile.current.click(); }} >
                                <p className="buildFileSelectorName">{IsValid(document.getElementById('uploadBuild')) && IsValid(document.getElementById('uploadBuild').files[0]) ? document.getElementById('uploadBuild').files[0].name : "Upload Build"}</p>
                                <p className="uploadText">Click to select file to upload.</p>
                                {buildUploadProgress !== null &&
                                    <span><br /> <progress id="build" value={(buildUploadProgress.loaded / buildUploadProgress.total) * 100} max="100" /></span>
                                }
                            </div>
                        </div>
                        <div className="buildFieldsDiv">
                            <div className="buildLabelsDiv">
                                <p className="buildNameLabel">File Name:</p>
                                <p className="buildPublicLabel">Is Public:</p>
                                <p className="buildWindowsLabel">Default Windows Build:</p>
                                <p className="buildMacLabel">Default Mac Build:</p>
                                <p className="buildLinuxLabel">Default Linux Build:</p>
                                <p className="buildDeleteLabel">Delete Build:</p>
                                <div className="buildLabelSpacer" />
                            </div>
                            <div className="buildEntriesDiv">
                                {IsValid(gameInfo.PrivateBuildLinks) === true &&
                                    gameInfo.PrivateBuildLinks.map((link, index) => {
                                        return <GameBuildEntry linkInfo={link} gameInfo={gameInfo} setRefresh={setRefresh} updateBuildLinks={GetGameInfo} />
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    {gameData !== undefined && gameData.length > 0 && <div>
                        <h3 className="gameEventHeader">Recent Events</h3>
                        <span className="gameEventEntry"> <p>UserId</p> <p>Timestamp</p> <p>Event</p> </span>
                        {gameData.map((event, index) => {
                            return <span className="gameEventEntry"> <p>{event.UserID}</p> <p>{event.Timestamp.slice(0, 19).replace('T', ' ')}</p> <p>{event.Event}</p> </span>
                        })}
                    </div>}
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 1 &&
                <div>
                    <p className="gameUnderReview">Your game is under review.</p>
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 2 &&
                <div>
                    <p className="gameChangesNeeded">Please edit your game to be approved.</p>
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 3 &&
                <div>
                    <p className="gameDenied">Your game application is deined.</p>
                </div>
            }
        </div>
    );
}


function GameTagEntry({ tagName, tagId, currGameTags, setCurrGameTags }) {
    const [refresh, setRefesh] = useState(false);

    useEffect(() => {
        if (refresh === true) {
            setRefesh(false);
        }
    }, [refresh])

    function AddTag(e) {
        e.preventDefault();

        var tagArray = currGameTags !== null ? currGameTags.split(',') : [];

        if (e.target.checked === false) {
            if (tagArray.length === 1) {
                setCurrGameTags("");
                return;
            }
            tagArray = tagArray.filter((id) => id !== tagId.toString());
        }
        else {
            tagArray.push(tagId);
        }
        setCurrGameTags(tagArray.join(','));
        setRefesh(true);
    }

    function CheckIsEnabled() {
        if (IsValid(currGameTags) !== true) return false;

        var tagArray = currGameTags.split(',');

        for (var i = 0; i < tagArray.length; i++) {
            if (tagArray[i] === tagId.toString()) {
                return true;
            }
        }

        return false;
    }

    return (
        <div className="gameTagEntry">
            {refresh === false && <input className="gameTagCheck" type="checkbox" onChange={AddTag} checked={CheckIsEnabled()} disabled={IsValid(currGameTags) === true && currGameTags.split(',').length >= 4 && currGameTags.split(',').includes(tagId) === false} />}
            <p className="gameTagName">{tagName}</p>
        </div>
    );
}

function GameBuildEntry({ linkInfo, gameInfo, setRefresh, updateBuildLinks }) {
    const [waitForResponse, setWaitForResponse] = useState(false);

    function OnChangePublic(e) {

        setWaitForResponse(true);

        //console.log(e.target.checked);

        if (e.target.checked === true) {
            const signUpReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': linkInfo })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetBuildPublic', signUpReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    setWaitForResponse(false);
                    //console.log(data);
                    if (data.success === true) {
                        updateBuildLinks();
                        setRefresh(true);
                    }
                });
        }
        else {
            const signUpReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': linkInfo })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/RemoveBuildPublic', signUpReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    setWaitForResponse(false);
                    //console.log(data);
                    if (data.success === true) {
                        updateBuildLinks();
                        setRefresh(true);
                    }
                });
        }
    }

    function SetWindowsBuild(e) {
        setWaitForResponse(true);

        var LinkToSend = linkInfo;

        if (e.target.checked === false) {
            LinkToSend = null;
        }

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': LinkToSend })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetWindowsBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function SetMacBuild(e) {
        setWaitForResponse(true);

        var LinkToSend = linkInfo;

        if (e.target.checked === false) {
            LinkToSend = null;
        }

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': LinkToSend })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetMacBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function SetLinuxBuild(e) {
        setWaitForResponse(true);

        var LinkToSend = linkInfo;

        if (e.target.checked === false) {
            LinkToSend = null;
        }

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': LinkToSend })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetLinuxBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function DeleteBuild(e) {
        setWaitForResponse(true);

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': linkInfo })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/RemoveBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function CheckPublic() {
        return IsValid(gameInfo.PublicBuildLinks) && gameInfo.PublicBuildLinks.includes(linkInfo);
    }

    return (
        <div>
            {IsValid(linkInfo) === true && <div className="buildEntryDiv">
                <p className="buildName">{linkInfo.split('/').pop()}</p>
                <input className="buildPublicCheck" type="checkbox" checked={CheckPublic()} onChange={OnChangePublic} disabled={(waitForResponse !== false)} />
                <input className="buildWindowsCheck" type="checkbox" checked={gameInfo.WindowsBuild === linkInfo} onChange={SetWindowsBuild} disabled={(waitForResponse !== false)} />
                <input className="buildMacCheck" type="checkbox" checked={gameInfo.MacBuild === linkInfo} onChange={SetMacBuild} disabled={(waitForResponse !== false)} />
                <input className="buildLinuxCheck" type="checkbox" checked={gameInfo.LinuxBuild === linkInfo} onChange={SetLinuxBuild} disabled={(waitForResponse !== false)} />
                <button className="deleteBuildButton" onClick={DeleteBuild} disabled={(waitForResponse !== false)}>Delete</button>
            </div>}
        </div>
    );
}


export default GamePage;