// JavaScript source code
import "./styles/CheckoutForm.css";
import React, { useEffect, useState, useRef } from "react";
//import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout, CheckoutForm , Elements, PaymentElement} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function LudareCheckoutForm({ accountInfo }) {
    const [clientSecret, setClientSecret] = useState('');

    function CheckoutChange() {
        window.location.reload();
    }

    useEffect(() => {

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetupPaymentSession', reqOptions)
            .then((res) => res.json())
            .then((data) => { setClientSecret(data.clientSecret); });
    }, [accountInfo]);

    const options = { clientSecret };

    return (
        <div className="addPaymentContainer">
            <div className="checkoutForm">
                {clientSecret && ( 
                    <EmbeddedCheckoutProvider stripe={stripePromise} options={options} onSubmit={CheckoutChange}>
                        <EmbeddedCheckout  />
                    </EmbeddedCheckoutProvider>
                )}
            </div>
        </div>
    );

}

export default LudareCheckoutForm;
