// JavaScript source code
import './../styles/AccountComponents/NewGamePage.css';
import { useEffect, useState, useRef } from 'react';
import { GetAccessToken, IsValid, FetchAccountInfo } from "./../Utilities";
import LoadingPopup from './../LoadingPopup';

function NewGamePage({ developerId }) {
    const gameName = useRef();
    const [message, setMessage] = useState();
    const description = useRef();
    const buildLink = useRef();
    const imageLink = useRef();
    const [loading, setLoading] = useState(false);

    function SubmitNewGame(event) {
        event.preventDefault();

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/MakeNewGame";

        if (IsValid(gameName.current) === false ||
            IsValid(description.current) === false ||
            IsValid(buildLink.current) === false ||
            IsValid(imageLink.current) === false) {

            console.log("Missing Param");
            setMessage("Missing at least one required parameter");
            return;
        }

        setLoading(true);

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameName': gameName.current, 'developerId': developerId, 'description': description.current, 'imageLink': imageLink.current, 'buildLink': buildLink.current })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    window.location.assign("/Account?loadPage=Developer&loadDev=" + developerId + "&loadGame=" + data.message);
                }
                else {
                    setMessage(data.message);
                }
                setLoading(false);
            });
    }

    return (
        <div className="newGameDiv">
            {loading === true && <LoadingPopup />}
            {message !== undefined && <p className="newGameMessage">{message}</p>}
            <form className="newGameForm">
                <label htmlFor="gameName">Game Name: </label>
                <input type="text" id="gameName" onChange={(e) => { gameName.current = e.target.value }} /> <br />
                <label htmlFor="description">Description: </label>
                <input type="text" id="description" onChange={(e) => { description.current = e.target.value }} /> <br />
                <label htmlFor="buildLink">Build Link: </label>
                <input type="text" id="buildLink" onChange={(e) => { buildLink.current = e.target.value }} /> <br />
                <label htmlFor="imageLink">Image Link: </label>
                <input type="text" id="imageLink" onChange={(e) => { imageLink.current = e.target.value }} /> <br />
                <button className="newGameSubmit accountPageButton" disabled={loading !== false} onClick={SubmitNewGame}>Make New Game</button>
            </form>
        </div>
    );
}

export default NewGamePage;