// JavaScript source code
import './../styles/AccountComponents/DeveloperPage.css';
import { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate, Navigate, useParams } from "react-router-dom";
import { GetAccessToken, IsValid, FetchAccountInfo } from "./../Utilities";
import LoadingPopup from './../LoadingPopup';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import GamePage from './GamePage.js';
import NewGamePage from './NewGamePage.js';

function DeveloperPage({ devId, accountInfo, gameArray, devArray, currGame, setCurrGame }) {
    const [currPane, setCurrPane] = useState();
    const [gameDropDownVisible, setGameDropDownVisible] = useState(false);
    const [devInfo, setDevInfo] = useState();
    const [devAddress, setDevAddress] = useState();
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [stateCountry, setStateCountry] = useState();
    const [stateRegion, setStateRegion] = useState();
    const { page, id } = useParams();

    const studioName = useRef();
    const contactEmail = useRef();
    const streetAddress1 = useRef();
    const streetAddress2 = useRef();
    const city = useRef();
    const region = useRef();
    const country = useRef();
    const zipCode = useRef();
    const EIN = useRef();
    const addressUpdate = useRef(false);

    function ClickInfo() {
        setCurrPane("DevInfo");
    }

    useEffect(() => {

        var tmpDevInfo;

        if (devArray === undefined || devArray === null) return;

        for (var i = 0; i < devArray.length; i++) {
            if (IsValid(id) === true && parseInt(id) === devArray[i].DeveloperID) {
                tmpDevInfo = devArray[i];
                break;
            }
        }

        if (tmpDevInfo !== undefined) {
            var addr = JSON.parse(tmpDevInfo.Address);
            setDevAddress(addr);
            setStateCountry(addr.country);
            setStateRegion(addr.region);
        }

        setDevInfo(tmpDevInfo)
    }, [devArray, id]);

    useEffect(() => {

        for (var i = 0; i < devArray.length; i++) {
            if (IsValid(id) === true && parseInt(id) === devArray[i].DeveloperID) {
                setDevInfo(devArray[i]);
                if (IsValid(devArray[i].Address)) {
                    setDevAddress(JSON.parse(devArray[i].Address));
                }
                break;
            }
        }

    }, [id]);

    function StartEdit() {
        addressUpdate.current = false;
        setEditing(true);
    }

    function EndEdit() {
        setEditing(false);
    }

    function EndEditUpdate(event) {
        event.preventDefault();
        setLoading(true);

        var address = null;

        if (addressUpdate.current === true) {
            address = {
                'streetAddress1': devAddress.streetAddress1,
                'streetAddress2': devAddress.streetAddress2,
                'city': devAddress.city,
                'region': stateRegion,
                'country': stateCountry,
                'zipCode': devAddress.zipCode
            };
            if (streetAddress1.current !== undefined) {
                address['streetAddress1'] = streetAddress1.current;
            }
            if (streetAddress2.current !== undefined) {
                address['streetAddress2'] = streetAddress2.current;
            }
            if (city.current !== undefined) {
                address['city'] = city.current;
            }
            if (zipCode !== undefined) {
                address['zipCode'] = zipCode.current;
            }
        }

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/UpdateDeveloper";

        var updateParams = { 'devId': devId };

        if (studioName.current !== null) {
            updateParams['studioName'] = studioName.current;
        }

        if (contactEmail.current !== null) {
            updateParams['email'] = contactEmail.current;
        }

        if (address !== null) {
            updateParams['address'] = JSON.stringify(address);
        }

        if (EIN.current !== null) {
            updateParams['ein'] = EIN.current;
        }

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updateParams)
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {

                if (data.success === true) {
                    var devURL = process.env.REACT_APP_NODE_ADDRESS + "/GetDeveloperInfo";

                    const devReqOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 'developerId': devId })
                    };

                    fetch(devURL, devReqOptions)
                        .then((res) => res.json())
                        .then((data) => {

                            if (data.success === true) {
                                setDevInfo(data.message)

                                for (var i = 0; i < devArray.length; i++) {

                                    if (id !== undefined && id.toString() === devArray[i].DeveloperID.toString()) {
                                        devArray[i] = data.message;
                                        break;
                                    }
                                }
                            }
                            var addr = JSON.parse(data.message.Address);
                            setDevAddress(addr);
                            setStateCountry(addr.country);
                            setStateRegion(addr.region);
                            setLoading(false);
                            setEditing(false);
                        });
                }
                else {

                    setLoading(false);
                    setEditing(false);
                }

            });
    }

    function UpdateCountry(val) {
        addressUpdate.current = true;
        country.current = val;
        setStateCountry(country.current);
    }

    function UpdateState(val) {
        addressUpdate.current = true;
        region.current = val;
        setStateRegion(region.current);
    }

    return (
        <div>
            {loading === true &&
                <LoadingPopup />
            }
            <div className="devPagePane">
                {devInfo !== undefined && <h1 className="developerTitle">{devInfo.DevName}</h1>}
            
                {devInfo !== undefined && devInfo.Status === 1 && <div>
                    <p className="devPending">Developer approval pending.</p>
                </div>}
                {devInfo !== undefined && devInfo.Status === 2 && <div>
                    <p className="devDenied">Developer request needs changes.</p>
                </div>}
                {devInfo !== undefined && devInfo.Status === 3 && <div>
                    <p className="devDenied">Developer request denied.</p>
                </div>}
                {devInfo !== undefined && devInfo.Status === 4 && editing !== true && <div>
                    <div className="devPageHeader">
                        <div className="gameHoverDiv" onMouseEnter={(e) => { setGameDropDownVisible(true) }} onMouseLeave={(e) => { setGameDropDownVisible(false) }}>
                            <button className="gameDropDownButton" style={{ 'background-color': (currPane !== "DevInfo") ? "#ffcf00" : "lightgray" }}><GameTableEntry gameInfo={currGame} setCurrGame={setCurrGame} setCurrPane={setCurrPane} /></button>
                            <div className="gameDropDownDiv">
                                {gameDropDownVisible === true && <div className="gameDropDownList">
                                    {gameArray !== undefined && gameArray.length > 0 && gameArray.map((gameInfo, index) => {
                                        return <div className="gameTableEntryParent"><GameTableEntry gameInfo={gameInfo} setCurrGame={setCurrGame} setCurrPane={setCurrPane} /><hr /></div>
                                    })}
                                    <GameTableEntry setCurrGame={setCurrGame} setCurrPane={setCurrPane} />
                                </div>}
                            </div>
                        </div>
                        <button className="developerInfoButton" onClick={ClickInfo} style={{ 'background-color': (currPane === "DevInfo") ? "#ffcf00" : "lightgray" }}>Developer Info</button>
                    </div>
                    {currPane === "DevInfo" && devInfo !== undefined &&
                        <div className="devInfoPane">

                            <div>
                                <p>Address:</p>
                                <p>{devAddress.streetAddress1 + " " + devAddress.streetAddress2 + " " + devAddress.city + " " + devAddress.state + ", " + devAddress.country + " " + devAddress.zipCode}</p>
                            </div>
                            <div>
                                <p>Email:</p>
                                <p>{devInfo.ContactEmail}</p>
                            </div>
                            <button className="developerEditButton accountPageButton" onClick={StartEdit}>Edit Information</button>
                        </div>
                    }
                    {(currPane === undefined || currPane === "GameInfo") &&
                        <div>
                            {currGame !== undefined &&
                                <GamePage gameId={currGame} />
                            }
                            {currGame === undefined &&
                                <NewGamePage developerId={id} />
                            }
                        </div>
                    }
                </div>}
                {devInfo !== undefined && devInfo.Status === 4 && editing === true && <div>
                    <form className="developerInfoForm">
                        <div>
                            <label htmlFor="studioName">Studio Name:</label>
                            <input type="text" id="studioName" required onChange={(e) => studioName.current = e.target.value} defaultValue={devInfo.DevName} />
                        </div>
                        <div>
                            <label htmlFor="studioEmail">Studio Contact Email:</label>
                            <input type="email" id="studioEmail" required onChange={(e) => contactEmail.current = e.target.value} defaultValue={devInfo.ContactEmail} />
                        </div>
                        <div>
                            <label htmlFor="streetAddress1">Street Address 1:</label>
                            <input type="text" id="streetAddress1" required onChange={(e) => { streetAddress1.current = e.target.value; addressUpdate.current = true; } } defaultValue={devAddress.streetAddress1} />
                        </div>
                        <div>
                            <label htmlFor="streetAddress2">Street Address 2:</label>
                            <input type="text" id="streetAddress2" onChange={(e) => { streetAddress2.current = e.target.value; addressUpdate.current = true; }} defaultValue={devAddress.streetAddress2} />
                        </div>
                        <div>
                            <label htmlFor="city">City:</label>
                            <input type="text" id="city" required onChange={(e) => { city.current = e.target.value; addressUpdate.current = true; } } defaultValue={devAddress.city} />
                        </div>
                        <div>
                            <label htmlFor="country">Country:</label>
                            <CountryDropdown onChange={UpdateCountry} required value={stateCountry} style={{
                                float: 'right',
                                width: '50%',
                                height: 'fit-content',
                                margin: 'auto', 'margin-left': '0px'
                            }} />
                        </div>
                        <div>
                            <label htmlFor="state">State:</label>
                            <RegionDropdown country={stateCountry} required value={stateRegion} onChange={UpdateState} style={{
                                float: 'right',
                                width: '50%',
                                height: 'fit-content',
                                margin: 'auto', 'margin-left': '0px'
                            }} />
                        </div>
                        <div>
                            <label htmlFor="zipCode">Zip Code:</label>
                            <input type="text" id="zipcode" required onChange={(e) => { zipCode.current = e.target.value; addressUpdate.current = true; } } defaultValue={devAddress.zipCode} />
                        </div>
                        <div>
                            <label htmlFor="ein">Tax Identification Number:</label>
                            <input type="text" id="ein" required onChange={(e) => EIN.current = e.target.value} defaultValue={devInfo.TaxID} />
                        </div>
                        <button className="accountPageButton" onClick={EndEditUpdate}>Update</button>
                        <button className="accountPageButton" onClick={EndEdit}>Cancel</button>
                    </form>
                </div>}
            </div>
        </div>
    );
}

function GameTableEntry({ gameInfo, setCurrGame, setCurrPane }) {
    const [gameTable, setGameTable] = useState(null);

    useEffect(() => {
        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetGameInfo', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setGameTable(data.message);
                }
            });
    }, [gameInfo]);

    function GameClick() {
        setCurrGame(gameInfo);
        setCurrPane("GameInfo");
    }

    return (
        <div className="gameTableEntry">
            {(IsValid(gameTable) === true || IsValid(gameInfo) === false) &&
                <p onClick={GameClick}>{(IsValid(gameInfo) === false) ? "Make New Game" : gameTable.Name}</p>
            }
        </div>
    );
}

export default DeveloperPage;