// JavaScript source code
import './styles/ExpressInterest.css';

function ExpressInterest() {

    return (
        <div className="baseInterest">
            <ul>
                <li className="playerInterestSelect">
                    <a href="/playerSignUp"><h1>PLAYERS</h1>
                    <img src="/gamepad.svg" alt="Game Controller" /></a>
                </li>
                <li className="studioInterestSelect">
                    <a href='/gameSignUp'><h1>STUDIOS</h1>
                    <img src="/developer.svg" alt="Game Developers Working" /></a>
                </li>
            </ul>
        </div>
    );
}

export default ExpressInterest;
