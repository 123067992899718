// JavaScript source code
import './../styles/AccountComponents/SelectPaymentMethod.css';
import { useNavigate } from "react-router-dom";
import { IsValid, FetchAccountInfo, GetPaymentMethods, GetSubscription } from "./../Utilities";
import { useEffect, useState } from 'react';
import { SelectPaymentMethodListItem, NewPaymentMethodListItem } from './SelectPaymentMethod.js';
import LoadingPopup from '../LoadingPopup';

function UpdatePaymentMethod() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(undefined);
    const [accountInfo, setAccountInfo] = useState(undefined);
    const [subscription, setSubscription] = useState(undefined);
    const [subUpdating, setSubUpdating] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        FetchAccountInfo(setAccountInfo);
    }, []);

    useEffect(() => {
        console.log(accountInfo);
        if (IsValid(accountInfo) === false) return;
        GetPaymentMethods(accountInfo, setPaymentMethods);
        GetSubscription(accountInfo, setSubscription);
    }, [accountInfo]);

    useEffect(() => {
        console.log(subscription);
        if (IsValid(subscription) === false) return;
        setSelectedMethod(subscription.default_payment_method);
        console.log(subscription.default_payment_method);
        if (IsValid(paymentMethods) === false) return;
        for (var i = 0; i < paymentMethods.length; i++) {
            if (paymentMethods[i].id === subscription.default_payment_method) {
                setSelectedMethod(paymentMethods[i]);
            }
        }
    }, [subscription, paymentMethods])

    function ConfirmPayment() {
        localStorage.setItem('paymentMethod', selectedMethod);
        navigate("/account/confirmSubscription");
    }

    function SetDefaultPayment(paymentMethod) {

        if (IsValid(selectedMethod) === false) return;

        var postData = { customer: accountInfo.StripeId, paymentMethod: selectedMethod };

        if (subscription !== null && subscription !== undefined) {
            postData = { customer: accountInfo.StripeId, paymentMethod: selectedMethod, subscription: subscription.id };
        }
        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/SetDefaultPayment", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                navigate("/account/subscription");
            });
    }

    return (
        <div className="paymentMethodList">
            {paymentMethods.map((method) => {
                return <SelectPaymentMethodListItem currentSelect={selectedMethod} paymentMethod={method} clickable={true} breakAfter={true} selected={setSelectedMethod} subscription={subscription} />;
            })}
            <NewPaymentMethodListItem accountInfo={accountInfo} currentSelect={selectedMethod} selected={setSelectedMethod} />
            {IsValid(selectedMethod) === true && selectedMethod !== -1 && <button className="accountPageButton confirmSubscriptionPayment" onClick={SetDefaultPayment}>Update Payment</button> }
        </div>
    );
}

export default UpdatePaymentMethod;