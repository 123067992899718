// JavaScript source code
import './../styles/AccountComponents/SubscriptionConfirm.css';
import { useSearchParams, useNavigate, Navigate, useParams } from "react-router-dom";
import { GetAccessToken, IsValid, FetchAccountInfo, GetPaymentMethods, GetSubscription } from "./../Utilities";
import { useEffect, useState, useRef, isValidElement } from 'react';
import { PaymentMethodListItem } from './SubscriptionInfo';
import ReactGA from 'react-ga4';

function SubscriptionConfirmUpdate() {
    const [accountInfo, setAccountInfo] = useState();
    const [paymentMethods, setPaymentMethods] = useState();
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState();
    const [subscriptionLevel, setSubscriptionLevel] = useState();
    const [selectedMethod, setSelectedMethod] = useState();
    const [subscription, setSubscription] = useState();
    const [subUpdating, setSubUpdating] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        FetchAccountInfo(setAccountInfo);
        setSelectedPaymentMethodId(localStorage.getItem('paymentMethod'));
        setSubscriptionLevel(localStorage.getItem('subscriptionLevel'));
        GetSubscription(setSubscription);
    }, []);

    useEffect(() => {
        if (IsValid(accountInfo) === false) return;
        GetPaymentMethods(accountInfo, setPaymentMethods);
    }, [accountInfo]);

    function StartSubscription() {
        //setSubUpdating(true);

        console.log(subscriptionLevel);

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId, 'price': subscriptionLevel, "paymentSource": selectedPaymentMethodId, "trial": accountInfo.TrialUsed !== 1 }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/CreateSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                //setSubUpdating(false);
                console.log(data);
                if (data.success) {
                    var callback = function () {
                        GetPaymentMethods(accountInfo, setPaymentMethods);
                        //LoadSubscriptionTilValid();
                        navigate('/account/subscription');
                    }

                    ReactGA.gtag('event', 'conversion_event_subscribe_paid', {
                        'event_callback': callback,
                        'event_timeout': 2000,
                    });
                }
            });
    }

    function UpdateSubscription(newSubscription){

        if (subscription === null || subscription === undefined) return;

        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ price: subscriptionLevel, subscriptionId: subscription.id }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/UpdateSubscriptionAmount", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
            });

    }

    function CancelMakingSubscription(){
        navigate('/account/subscription');
    }

    return (
        <div className="confirmSubscriptionContainer">
            <div className="subscriptionConfirmInfo">
                <h1 className="subscriptionConfirmInfoTitle">Subscription:</h1>
                {IsValid(accountInfo) === true && accountInfo.TrialUsed !== 1 && <p className="subscriptionConfirmInfoText">7 day free trial and then:</p>}
                <p className="subscriptionConfirmInfoText">{(subscriptionLevel/100) + "/ Month"}</p>
            </div>
            <div className="subscriptionConfirmPaymentInfo">
                <h1 className="subscriptionConfirmPaymentInfoTitle">Payment Method:</h1>
                {IsValid(paymentMethods) === true && IsValid(selectedPaymentMethodId) === true && paymentMethods.map((method) => {
                    if (method.id === selectedPaymentMethodId) {
                        return <PaymentMethodListItem paymentMethod={method} clickable={false} />
                    }
                })}
            </div>
            <div className="subscriptionConfirmButtons">
                <button className="accountPageButton" onClick={StartSubscription}>Update Subscription</button>
                <button className="accountPageButton" onClick={CancelMakingSubscription}>Cancel Update</button>
            </div>
        </div>
    );
}

export default SubscriptionConfirmUpdate;