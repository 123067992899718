// JavaScript source code
import '../styles/AccountComponents/PersonalAccountMenu.css';
import { useNavigate, useParams } from "react-router-dom";
import BasicAccountInfo from './BasicAccountInfo.js';
import { IsValid } from '../Utilities';
import SubscriptionInfo from './SubscriptionInfo';
import LinkedAccountInfo from './LinkedAccountInfo';
import SelectPaymentMethod from './SelectPaymentMethod.js';
import SubscriptionConfirm from './SubscriptionConfirm';
import SubscriptionConfirmUpdate from './SubscriptionConfirmUpdate.js';
import UpdatePaymentMethod from './UpdatePaymentMethod';

function PersonalAccountMenuBar() {
    const { page, id } = useParams();
    const navigate = useNavigate();

    return (
        <div className="personalMenuBar">
            <button className={"personalMenuButton " + ((IsValid(page) === false) ? "personalMenuButtonSelected" : "personalMenuButtonUnselected")} onClick={() => {navigate("/account") } }>Account Info</button>
            <button className={"personalMenuButton " + ((page === "subscription") ? "personalMenuButtonSelected" : "personalMenuButtonUnselected")} onClick={() => { navigate("/account/subscription") }} >Subscription</button>
            <button className={"personalMenuButton " + ((page === "linkAccounts") ? "personalMenuButtonSelected" : "personalMenuButtonUnselected")} onClick={() => { navigate("/account/linkAccounts") }} >Link Accounts</button>
        </div>
    )
}

function PersonalAccountMenu({ accountInfo, setAccountInfo }) {
    const { page, id } = useParams();

    return (
        <div>
            <PersonalAccountMenuBar />
            {(IsValid(page) === false) && <BasicAccountInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
            {(page === "subscription" || page === "updateSubscription") && <SubscriptionInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
            {(page === "linkAccounts") && <LinkedAccountInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
            {(page === "selectPaymentMethod") && <SelectPaymentMethod accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
            {(page === "confirmSubscription") && <SubscriptionConfirm accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
            {(page === "confirmUpdateSubscription") && <SubscriptionConfirmUpdate accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
            {(page === "updatePaymentMethod") && <UpdatePaymentMethod accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
        </div>
    );
}

export default PersonalAccountMenu;