// JavaScript source code
import '../styles/PopupAlerts/PopupMessageContainer.css';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import Popup from 'reactjs-popup';
import { GetAuthStatus } from '../Utilities.js';
import Cookies from 'js-cookie';

function PopupMessageContainer() {
    const navigate = useNavigate();
    const [authStatus, setAuthStatus] = useState(GetAuthStatus());
    const [isOpen, setIsOpen] = useState(true);

    function GoToSubscription() {
        Cookies.set("SeenPopup", true);
        if (authStatus === true) {
            navigate('/account/subscription');
        }
        else {
            navigate('/login');
        }
    }

    function ClosePopup() {
        Cookies.set("SeenPopup", true);
        setIsOpen(false);
    }

    function ClosePopupClicked() {
        Cookies.set("SeenPopup", true);
        setIsOpen(false);
    }

    return (
        <Popup open={isOpen} modal lockScroll onClose={ClosePopupClicked}>
            {close => (
                <div>
                    <p className="closePopupWindow" onClick={ClosePopupClicked}>X</p>
                    <div className="popupContainer">
                        <h1 className="freeTrialMessageTitle">Enjoy your first month on us.</h1>
                        <p className="freeTrialMessageContent">Start your subscription to access Ludare's library of games today and we will cover your first month's subscription.</p>
                        <button className="startSubscriptionPopup" onClick={GoToSubscription}>{authStatus ? "Start Subscription" : "Sign Up"}</button>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default PopupMessageContainer;