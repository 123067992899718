// JavaScript source code
import './styles/LoadingPopup.css';
import Popup from 'reactjs-popup';
import { useState } from 'react';

function LoadingPopup() {
    return (
        <div className="loadingIconDiv">
            <img src="/loading-placeholder.gif" alt="Loading Icon" />
        </div>
    );
}

export default LoadingPopup;
