// JavaScript source code
import './styles/PressKit.css';

function PressKit() {

    return (
        <div className="pressKitPage">
            <div>
                <h1 className="fullLogoTitle">Full Logo</h1>
                <img className="fullLogoImage" src="/LudareLogo.png" alt="Ludare Logo with black background" /> <br/>
                <a className="fullLogoDownload" href="LudareLogo.png" download>Download Full Ludare Logo With Black Background</a><br/>
                <img className="transLogoImage" src="/LudareLogoTransparent.png" alt="Ludare Logo with no background" /> <br />
                <a className="transLogoDownload" href="LudareLogoTransparent.png" download>Download Full Ludare Logo With No Background</a><br />
                <h1 className="iconTitle">Icon</h1>
                <img className="iconImage" src="/IconTransparent.png" alt="Ludare Icon" /> <br />
                <a className="iconDownload" href="IconTransparent.png" download>Download Ludare Icon With No Background</a><br />
            </div>
        </div>
        );
}

export default PressKit;
